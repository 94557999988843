import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyBjbXwE8fLPm-mOENKsWWS3vTO4OpvLso4",
//     authDomain: "proj-d-mobile.firebaseapp.com",
//     projectId: "proj-d-mobile",
//     storageBucket: "proj-d-mobile.appspot.com",
//     messagingSenderId: "912963129301",
//     appId: "1:912963129301:web:73611014f1b068b706375c",
//     measurementId: "G-GLF0KHS43C"
// };
// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Mount the VueJS app.
createApp(App).use(router).mount('#app')
