import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/ContinueToApp.vue')
  },
  {
    path: "/sign_up_by_email",
    component: () => import('../views/ContinueToApp.vue')
  },
  {
    path: "/sign_in_by_code",
    component: () => import('../views/ContinueToApp.vue')
  },
  {
    path: "/update_email",
    component: () => import('../views/ContinueToApp.vue')
  },
  {
    path: "/activate_sign_up",
    component: () => import('../views/ContinueToApp.vue')
  },
  {
    path: "/plaid",
    component: () => import('../views/ContinueToApp.vue')
  },
  {
    path: "/ness_card_invited",
    component: () => import('../views/ContinueToApp.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'Page not found',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
